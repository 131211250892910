import { render, staticRenderFns } from "./postCommentTaskModal.vue?vue&type=template&id=6ff2082e&scoped=true"
import script from "./postCommentTaskModal.vue?vue&type=script&lang=js"
export * from "./postCommentTaskModal.vue?vue&type=script&lang=js"
import style0 from "./postCommentTaskModal.vue?vue&type=style&index=0&id=6ff2082e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff2082e",
  null
  
)

export default component.exports