<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @cancel="visible = false"
  >
    <div v-if="hideLostList.length>0" class="list-div">
      <a-timeline>
        <a-timeline-item v-for="item in hideLostList" :key="item.id">
          <a-tag :color="item.status | dictName('postLogActStatus', 'color')" style="cursor: pointer">{{
              item.status | dictName("postLogActStatus")
            }}
          </a-tag>
          <span style="padding:0 10px;">{{ item.content }}</span>
          <span>{{ item.adminUser }}</span>
          <span style="padding:0 10px;">{{ item.createTime }}</span>
          <span style="padding:0 10px;">
            {{ item.hideMode ? item.hideMode.remark : '' }}
          </span>
          <span v-if="item.remark" style="padding:0 10px;">
            {{ item.remark || '' }}
          </span>
          <span v-if="item.hideMode&&item.hideMode.fromDate" style="padding:0 10px;">
            隐藏期间：{{ item.hideMode.fromDate }} - {{item.hideMode.toDate}}
          </span>
        </a-timeline-item>
      </a-timeline>
    </div>
    <div class="input-text" style="padding:10px 0">
      设置显示|隐藏模式：
      <a-radio-group v-model="mode">
        <a-radio :value="1">
          正常显示
        </a-radio>
        <a-radio :value="0">
          永久隐藏
        </a-radio>
        <a-radio :value="-1">
          临时隐藏7天
        </a-radio>
        <a-radio :value="-2">
          临时隐藏15天
        </a-radio>
      </a-radio-group>
    </div>
    <div class="input-text">
      <a-textarea
          v-model.trim="remark"
          placeholder="请输入处理理由"
          :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </div>
    <div>
      <div style="padding-top:10px;color:#FF9900;">隐藏常见原因（点击选择，可以多选）：</div>
      <a-tag color="red" v-for="item in reasonList" :key="item" style="cursor: pointer;margin:5px"
             @click="addReason(item)">
        {{ item }}
      </a-tag>
    </div>
    <template slot="footer">
      <div class="modal-btn">
        <a-button type="primary" :loading="confirmLoading" @click="handleOk(1)">
          确定设置
        </a-button>
        <a-button type="default" :loading="confirmLoading" @click="visible=false" style="margin-left:100px;">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: "PostHideModal",
  data() {
    return {
      visible: false,
      title: "",
      confirmLoading: false,
      mode: 0,
      remark: "",
      hideLostList: [],
      record: {}
    };
  },
  computed: {
    reasonList() {
      return '涉及方强烈反对,已提出异议，待提供证据,涉及方证据不足，恢复显示,其他'.split(/,/)
    }
  },
  methods: {
    showModal(record) {
      this.visible = true;
      this.record = record;
      this.mode = undefined;//record.hideMode ? record.hideMode.mode : record.isShow
      this.remark = "";
      this.title = "隐藏|显示帖子：" + record.id;
      this.hideLostList = [];
      this.$post("/post/hideHistory", {postId: record.id}).then(res => {
        this.hideLostList = res.data || []
      })
    },
    addReason(item) {
      this.remark = this.remark + (this.remark ? "，" : "") + item
    },
    handleOk(operate) {
      this.confirmLoading = true;
      if (this.mode === undefined) {
        this.confirmLoading = false;
        this.$message.error("请选择隐藏模式");
        return;
      }
      if (!this.remark) {
        this.confirmLoading = false;
        this.$message.error("请输入隐藏的理由");
        return;
      }
      let data = {
        postId: this.record.id,
        mode: this.mode,
        remark: this.remark,
      };

      this.$postJson('post/updateHideMode', data)
          .then((res) => {
            this.confirmLoading = false;
            this.visible = false;
            this.$emit("success", operate);
          })
          .catch((error) => {
            this.confirmLoading = false;
          });
    },
  },
};
</script>

<style lang="less" scoped>
.modal-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.list-div {
  max-height: 200px;
  overflow-y: auto;
  padding-top: 10px;

  /deep/ .ant-timeline-item {
    padding-bottom: 10px;
  }

  /deep/ .ant-timeline-item-content {
    min-height: auto;
  }
}
</style>
